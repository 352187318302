import { splitLocale } from '@/utils/plain/splitLocale.ts'
import { useEffect } from 'react'

declare global {
  interface Window {
    langQualtrics: string
  }
}

const DEFAULT_QUALTRICS_LANGUAGE = 'EN'

const availableQualtricsLanguagesPerLocale: Record<string, string> = {
  'sq-AL': 'SQI',
  'sq-XQ': 'SQI',
  'en-GB': 'EN-GB',
  'kk-KZ': 'KAZ',
  'hy-AM': 'HYE',
  'az-AZ': 'AZ-AZ',
  'es-ES': 'ES-ES',
  'ka-GE': 'KAT',
  'pt-BR': 'PT-BR',
  'me-ME': 'SR-ME',
}

const availableQualtricsLanguagesPerLang = [
  'nl',
  'ro',
  'ar',
  'ru',
  'en',
  'sr',
  'ko',
  'et',
  'lv',
  'sk',
  'sl',
  'lt',
  'fi',
  'id',
  'fr',
  'mk',
  'es',
  'sw',
  'el',
  'bg',
  'he',
  'no',
  'th',
  'hu',
  'tr',
  'uk',
  'it',
  'hr',
  'cs',
  'pt',
  'vi',
  'da',
  'ja',
  'de',
  'pl',
]
const mapToAvailableQualtricsLanguage = (locale: string) => {
  const { lang } = splitLocale(locale)
  if (typeof window === 'undefined') return

  if (locale in availableQualtricsLanguagesPerLocale) {
    window.langQualtrics =
      availableQualtricsLanguagesPerLocale[
        locale as keyof typeof availableQualtricsLanguagesPerLocale
      ]
  } else if (availableQualtricsLanguagesPerLang.includes(lang)) {
    window.langQualtrics = lang.toUpperCase()
  } else {
    window.langQualtrics = DEFAULT_QUALTRICS_LANGUAGE
  }
}

export const useMapToAvailableQualtricsLanguage = (locale: string) => {
  useEffect(() => {
    mapToAvailableQualtricsLanguage(locale)
  }, [locale])
}
